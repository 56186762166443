import * as React from "react"
import SharedHeader from "../components/general/components/SharedHeader"
import ResetPasswordPage from "../components/login/pages/ResetPasswordPage"


// markup
const Login = () => {
    return (
        <React.Fragment>
            <SharedHeader />
            <ResetPasswordPage />
        </React.Fragment>
    )
}

export default Login
