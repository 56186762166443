import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useLocation } from "@reach/router";
import { Button, Form, Input, message, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { Link, navigate } from "gatsby";
import get from "lodash.get";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "../../../locales/useTranslation";
import getEmailByEmailAndCode from "../../../newApi/email/getEmailByEmailAndCode";
import getProfiles from "../../../newApi/profile/getProfiles";
import patchPassword from "../../../newApi/user/patchPassword";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import { routes } from "../../../route";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";
import validateUsernameAndEmail from "../../../newApi/profile/validateUsernameAndEmail";

// markup
const LoginPage = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  // const [showError, setShowError] = useState();
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [checkCode, setCheckCode] = useState("");
  // const [visible, setVisible] = useState(false);
  const accessKey = get(props.user, "accessKey");

  useEffect(() => {
    let query = queryString.parse(location.search);
    if (!get(query, "email")) {
      message.error(t("emailRequired"));
      navigate(routes.forgotPassword.to());
    } else if (!get(query, "checkCode")) {
      message.error(t("emailCodeRequired"));
      navigate(routes.emailVerification.to({ email: get(query, "email") }));
    }
    if (!get(query, "username")) {
      message.error(t("usernameRequired"));
      navigate(routes.forgotPassword.to());
    }
    setEmail(get(query, "email"));
    setCheckCode(get(query, "checkCode"));
    setCheckCode(get(query, "checkCode"));
    setUsername(get(query, "username"));
  }, [location]);

  function handleSubmit() {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);

        getEmailByEmailAndCode(1, 0, { to: email, code: checkCode }, accessKey)
          .then((res) => {
            if (get(res, "total") == 1) {
              validateUsernameAndEmail({ username: username, email: email })
                .then((res) => {
                  let data = res?.data || {};
                  let userId = data?.refId;

                  if (userId) {
                    patchPassword({
                      userId: userId,
                      password: values.pass,
                    })
                      // feathers("users", accessKey)
                      //   .patch(userId, {
                      //     password: values.pass,
                      //     type: "resetPassword",
                      //   })
                      .then((res) => {
                        message.success(
                          <React.Fragment>
                            {/* 已成功设置 <br /> */}
                            {t("resetSuccess")}
                          </React.Fragment>
                        );
                        setLoading(false);
                        navigate(routes.login.to());
                      })
                      .catch((err) => {
                        message.error(t("resetFail"));
                        setLoading(false);
                      });
                  } else {
                    message.error(t("emailNotBelongToUser"));
                    setLoading(false);
                  }
                })
                .catch((err) => {
                  console.log(err);
                  message.error(err.message);
                  setLoading(false);
                });
            } else {
              message.error(t("codeNotMatched"));
              setLoading(false);
            }
          })
          .catch((err) => {
            message.error(t("failGetEmailRecord"));
            setLoading(false);
          });
      })
      .catch((err) => {
        message.error(err.message);
        setLoading(false);
      });
  }

  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          <NavHeader
            showBack
            onBack={() => {
              navigate(routes.login.to());
            }}
            suffix={
              <div className="justify-end flex items-center">
                {/* 忘记密码{" "}
                <MediaQuery maxWidth={440}>
                  <br />
                </MediaQuery> */}
                {t("forgotPassword2")}
              </div>
            }
          >
            <div className="py-5 px-10">
              <Form form={form} style={{ color: "black" }}>
                <div className="my-5  font-semibold text-xl">
                  {/* 新密码
                  <br /> */}
                  {t("newPassword")}
                </div>
                <div className="my-5 text-sm font-thin">
                  {/* 重置您的密码以登录到您的账户 */}
                  <br />
                  {t("restPasswordDesc")}
                </div>
                <div className="mb-5 text-xs">
                  {/* 密码条件: 8 到 16 个字母（含 1 个小写字母、1 个大写字母和 1
                  个数字） <br />  */}
                  {t("passwordCriteria")}
                </div>
                <div className="my-5">
                  <Form.Item
                    name="pass"
                    required
                    style={{ margin: 0 }}
                    initialValue=""
                    rules={[
                      {
                        required: true,
                        message: (
                          <React.Fragment>
                            {/* 请填写新密码
                            <br /> */}
                            {t("newPasswordRequired")}
                          </React.Fragment>
                        ),
                      },
                      {
                        pattern: new RegExp(
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]{8,30}$/
                        ),
                        message: t("passwordValidation"),
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder={t("insertPassword")}
                      maxLength="30"
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                      className={"input-border"}
                    />
                  </Form.Item>
                </div>
                <div className="my-5">
                  <Form.Item
                    name="confirmPass"
                    required
                    initialValue=""
                    style={{ margin: 0 }}
                    rules={[
                      {
                        required: true,
                        message: (
                          <React.Fragment>
                            {/* 请确认新密码
                            <br /> */}
                            {t("confirmPasswordRequired")}
                          </React.Fragment>
                        ),
                      },
                      {
                        validator: (rule, value, callback) => {
                          if (value !== form.getFieldValue("pass") && value) {
                            callback(t("passwordNotMatch"));
                          } else {
                            callback();
                          }
                        },
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder={t("insertConfirmPassword")}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                      className={"input-border"}
                    />
                  </Form.Item>
                </div>
                <div className="my-5 flex justify-center items-center">
                  <Button
                    className="main-button-color input-border"
                    block
                    onClick={(e) => {
                      handleSubmit();
                    }}
                  >
                    {t("confirm")}
                  </Button>
                </div>
              </Form>

              <Link to={routes.home.to()}>
                <div
                  className="flex justify-center items-center mt-5 underline "
                  style={{ color: "#00B2FF" }}
                >
                  {t("home")}
                </div>
              </Link>
            </div>
          </NavHeader>
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
